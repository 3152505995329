import React from "react";
import CarouselHome from "../Components/HomeCarousel";
import img1 from "../img/1.png";
import img2 from "../img/2.png";
import img3 from "../img/3.png";
import img4 from "../img/4.png";
import special from "../img/img1.png";
import {Fade, JackInTheBox, Slide} from "react-awesome-reveal";
import SwiperSer from "../Components/SwiperSer";
import imgser from "../img/Carousel/1.png";
import ProductData from "../Components/Products";
import SwiperReview from "../Components/SwiperReview";
import Popup from "../Components/Popup";


export default function Home(){
    return (
        <main className=" relative overflow-hidden">

            <Popup />
            
            <section>
                <CarouselHome />
            </section>

            <section className=" bg-[#fcfaf5] pt-6 pb-8 lg:pt-24 lg:pb-24 space-y-4">
                <div className="lg:p-4 p-2 mx-auto max-w-screen-xl">
                    <div className=" grid gap-4 lg:gap-12 place-items-center lg:grid-cols-4">
                        <Fade>
                            <div className=" flex flex-col lg:space-y-8">
                                <img alt="img1" src={img1} />
                                <div className=" pb-8"></div>
                                <img alt="img2" className="ml-4 hidden lg:block" src={img2} />
                            </div>
                        </Fade>
                        <div className=" flex lg:col-span-2 space-y-4 lg:space-y-8 flex-col capitalize text-center">
                            <span style={{fontFamily: 'Redressed'}} className=" text-2xl text-black/40">About us</span>
                            <div className=" flex flex-col space-y-4">
                                <span className=" text-4xl font-header-font text-black">
                                    Revel in your innate charm with a novel
                                </span>
                                <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                    Outlook
                                </span>
                            </div>
                            <p className=" text-xl text-black/60 font-txt-font">
                                Experience top-tier nail and facial care at T&T Beauty Spa in Pleasant Hills, CA. 
                                Enjoy luxury treatments from skilled technicians in our modern, elegant salon. Visit us for exceptional service and beautiful results.
                            </p>
                            <div className="">
                                <button onClick={() => {
                                    window.location.href = 'tel:9253499958';
                                }} className="hover:shadow-lg font-txt-font hover:-translate-y-2 hover:bg-main-color hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-xl border-2 border-main-color text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                                    <span className="">Book Now</span>
                                </button>
                            </div>
                        </div>

                        <Fade>
                            <div className=" hidden lg:flex flex-col lg:space-y-8">
                                <img className="" alt="img3" src={img3} />
                                <div className=" pb-8"></div>
                                <img alt="img4" className="ml-4" src={img4} />
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>

            <section className=" p-5 pt-6 pb-8 lg:pt-24 lg:pb-24 bg-[#faf3ee]">
                <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                    <div className="flex flex-col text-center space-y-8">
                        <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Our Services</span>
                        <div className=" flex flex-col space-y-4">
                            <span className=" text-4xl italic font-header-font text-black">
                                Embrace your unique allure with a fresh
                            </span>
                            <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                Perspective
                            </span>
                        </div>
                    </div>
                    <div>
                        <Slide delay={10} direction="left">
                            <SwiperSer />
                        </Slide>
                    </div>
                </div>
            </section>

            <section className=" p-5 pt-6 pb-8 lg:pt-24 lg:pb-24 bg-[#fcfaf5]">
                <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                    <div className=" grid gap-8 lg:grid-cols-2">
                        <Fade>
                            <div className="flex flex-col space-y-8">
                                <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Discover Excellence</span>
                                <div className=" flex flex-col space-y-4">
                                    <span className=" text-4xl font-header-font text-black">
                                        Expert pampering and luxurious
                                    </span>
                                    <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                        Treatments
                                    </span>
                                </div>
                                <span className=" text-lg text-black/80 font-txt-font">
                                    Choose T&T Beauty Spa for expert pampering and luxurious treatments. 
                                    Our salon offers top-quality beauty services in a tranquil setting, 
                                    ensuring you feel refreshed and radiant with each visit.
                                </span>
                                <div className=" font-txt-font">
                                    <ul className=" space-y-8">
                                        <li className=" flex flex-col">
                                            <div className=" flex space-x-2 text-2xl">
                                                <span style={{fontFamily: 'Calligraffitti'}} className="">01</span>
                                                <span className="">
                                                    Expertise and Precision
                                                </span>
                                            </div>
                                            <span className=" text-black/60 ml-8 text-lg font-thin">
                                                Experience premier nail care by our highly skilled technicians who are committed to perfection.
                                            </span>
                                        </li>
                                        <li className=" flex flex-col">
                                            <div className=" flex space-x-2 text-2xl">
                                                <span style={{fontFamily: 'Calligraffitti'}} className="">02</span>
                                                <span className="">
                                                    Luxurious Pampering
                                                </span>
                                            </div>
                                            <span className=" text-black/60 ml-8 text-lg font-thin">
                                                Escape the daily grind with indulgent relaxation and luxury at our salon. Every visit rejuvenates your spirit and enhances your beauty.                                        
                                            </span>
                                        </li>
                                        <li className=" flex flex-col">
                                            <div className=" flex space-x-2 text-2xl">
                                                <span style={{fontFamily: 'Calligraffitti'}} className="">03</span>
                                                <span className="">
                                                    Personalized Service
                                                </span>
                                            </div>
                                            <span className=" text-black/60 ml-8 text-lg font-thin">
                                                Our tailor-made treatments are designed to meet your unique needs in a serene environment, leaving you thoroughly pampered and satisfied."                                        
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <div className=" lg:hidden flex">
                            <img className=" h-full" alt="imgser" src={imgser} />
                        </div>
                        <div className="hidden lg:block lg:bg-bg1 lg:w-full rounded-lg cursor-pointer hover:opacity-50 lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                        </div>
                    </div>
                </div>  
            </section>

            <section className=" p-5 pt-6 pb-8 lg:pt-24 lg:pb-24 bg-[#faf3ee]">
                <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                    <div className="flex flex-col text-center space-y-8">
                        <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Our Products</span>
                        <div className=" flex flex-col space-y-4">
                            <span className=" text-4xl font-header-font italic text-black">
                                Reveal Your Beauty with Our Premium
                            </span>
                            <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                Products
                            </span>
                        </div>
                    </div>
                    <div className=" grid lg:grid-cols-4 gap-6">
                        {ProductData.map((s) => (
                            <Fade delay={100}>
                                <div key={s.id} className="shadow-lg cursor-pointer h-full pb-2 space-y-3 rounded-lg mb-2 ease-in-out transition-all duration-1000 lg:hover:scale-105">
                                    <img alt={s.name} className=" rounded-lg" src={s.img} />
                                    <div className=" flex flex-col m-4">
                                        <h1 style={{fontFamily: 'Satisfy'}} className="text-2xl text-main-color text-center py-1">{s.name}</h1>
                                        <span className=" font-Marcok text-sm indent-1">{s.desc}</span>
                                    </div>
                                </div>
                            </Fade>
                        ))}
                    </div>
                </div>
            </section>

            <section className=" p-5 pt-6 pb-8 lg:pt-24 lg:pb-24 bg-[#fcfaf5]">
                <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                    <div className="flex flex-col text-center space-y-4">
                        <span style={{fontFamily: 'Rerdessed'}} className=" text-3xl text-black/40">Testimonials</span>
                        <div className=" flex flex-col space-y-4">
                            <span className=" text-4xl font-header-font italic text-black">
                                Our Customer Reviews
                            </span>
                        </div>
                    </div>
                    <div>
                        <Slide direction="up">
                            <SwiperReview />
                        </Slide>
                    </div>
                    <div className=" flex justify-center">
                        <button onClick={() => {
                            window.open('https://www.yelp.com/biz/t-and-t-beauty-spa-pleasant-hill-2' , '__blank');
                        }} className="hover:shadow-lg font-txt-font hover:-translate-y-2 hover:bg-main-color hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-xl border-2 border-main-color text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                            <span className="">View More</span>
                        </button>
                    </div>
                </div>
            </section>

            <section className=" w-screen h-screen lg:bg-fixed bg-bgtest bg-center bg-cover">
                <div className=" bg-black w-full bg-opacity-60 h-screen p-5 pt-12 lg:pt-32">
                    <div className="mx-auto space-y-6 lg:space-y-8 h-full max-w-screen-xl">
                        <h1 style={{fontFamily: 'Redressed'}} className=" text-center text-white text-4xl lg:text-6xl">Our Specials</h1>
                        <div className="lg:h-[500px] gap-6 lg:gap-8 lg:p-4 grid lg:grid-cols-2">
                            <JackInTheBox>
                                <img onClick={() => {
                                    window.open(special , '__blank')
                                }} alt="special" className=" cursor-pointer hover:opacity-30 rounded-xl" src={special} />
                            </JackInTheBox>
                            <div className=" flex font-txt-font justify-center items-center text-2xl">
                                <ul className=" text-white list-inside list-disc space-y-4">
                                    
                                    <li>
                                        Sign up for our new e-newsletter and be entered to win our monthly drawing for $30, $20 or $10 T&T Beauty spa gift cards. Three winners every month!
                                    </li>

                                    <li className=" space-x-2">
                                        
                                        <span>
                                            We also accept 
                                        </span>
                                            
                                        <span className=" text-sky-300 cursor-pointer" onClick={() => {
                                            window.open('https://app.yiftee.com/gift-card/pleasanthill' , '__blank')
                                        }}>
                                            Pleasant Hill 
                                        </span>
                                            
                                        <span>
                                            Perks gift cards! Purchase one today and receive extra Perks!
                                        </span>
                                    </li>
  
                                    <li>
                                        We extend a 10% discount to all Seniors 65 and older every Tuesday!
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </main>
    );
};